<template>
  <div class="refundhandle">
    <title-top>退款详情</title-top>
    <div class="row align-center font32 f-white bg-F62341 h176">
      <span class="ml25">等待商家处理</span>
    </div>
    <div class="cloumn bg-white">
      <span class="font28 f-1A1A1A ml25">您已成功发起退款申请，请耐心等待商家处理。</span>
      <p class="font24 f-999999 ml25 mt25">如果商家拒绝您可以修改退款申请后再次发起，商家会重新处理</p>
      <div class="row font24 f-333333 flex-end mb20">
        <span class="row flex-center hd-border br8 mr16 mt50 mb32" @click="onCancle">撤销申请</span>
        <span class="row flex-center hd-border br8 mr25 mt50 mb32" @click="onXg">修改申请</span>
      </div>
    </div>
    <div class="column bg-white font28 f-1A1A1A mb40">
      <span class="ml25 mt25">退款信息</span>
      <div class="row between align-center bg-white" v-for="(item,i) in res.order.items" :key="i">
        <img class="imgsize176 br4 ml25 mr16 mt25 mb25" :src="item.image_url" alt />
        <div class="flex1 column font24 f-333333 mt25 mb25">
          <p class="mr25 ellipsis">{{item.product_name}}</p>
          <div class="row between f-999999 mt16 align-center">
            <span>{{item.specification_values | spec}}</span>
            <span class="ml25 mr25">×{{item.quantity}}</span>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="font24 f-999999 bg-white">
        <p class="mt25 ml25 mb16">退款原因：{{res.reason}}</p>
        <p class="ml25 mb16">退款金额：￥{{res.order.paid_amount}}</p>
        <p class="ml25 mb16">申请时间：{{res.created_at | formatDate}}</p>
        <p class="ml25 mb16">退款编号：{{res.number}}</p>
      </div>
      <div class="row flex-center bg-white h96" @click="onSug">
        <img class="imgsize48" src="~images/zyimg/business.png" alt />
        <span class="ml25 font28 f-666666">联系卖家</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { formatDate } from "common/util";
import { refundsDetail, cancelApply } from "network/igou2";
export default {
  name: "RefundHandle",
  data() {
    return {
      id: this.$route.query.id,
      res: { order: { items: [] } }
    };
  },
  mounted() {
    this._refundsDetail();
  },
  methods: {
    _refundsDetail() {
      refundsDetail(this.id).then(res => {
        console.log(res);
        this.res = res.data;
        // this.turn(res.data.state);
      });
    },

    _cancelApply() {
      cancelApply(this.id).then(res => {
        console.log(res);
        // location.reload();
        this.$router.back();
      });
    },
    onSug() {
      this.$router.push("/sug");
    },
    onCancle() {
      this._cancelApply();
    },
    onXg() {
      let state = this.res.state;
      if (state == "rejected" || state == "canceled") {
        this.$router.push({
          path: "/zychangeserve",
          query: { order_id: this.res.order_id, state: state }
        });
      } else {
        this.$router.push({
          path: "/zyapplyrefundedit",
          query: { order_id: this.res.order_id, id: this.res.id }
        });
      }
    }
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    spec(specification_values) {
      let str = "";
      specification_values.forEach(item => {
        str += item.content + ",";
      });
      if (str) {
        str.substring(0, str.length - 1);
      }
      return str;
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.hd-border {
  width: 1.96rem;
  height: 0.76rem;
  border: 1px solid #999999;
}
</style>
